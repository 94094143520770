import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowsRotate,
  faBell,
  faChartLine,
  faCheck,
  faEllipsisVertical,
  faList,
  faListCheck,
  faTrash,
  faUpRightAndDownLeftFromCenter,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import moment, { Moment } from "moment-timezone";
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Dropdown,
  Form,
  FormCheck,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { Line } from "react-chartjs-2";

import { CurrencyCode, Sort } from "../../def";
import { useHttpClient } from "../../http-client/HttpClient";
import { SourceSelect } from "../../molecules/SourceSelect";
import { TransRouterMotionSection } from "../../trans-router/TransRouterMotionSection";
import { useUser } from "../../user/User";
import {
  BadgeGoodTimeToBuy,
  BadgeIsSetting,
  ResearchList,
  ResearchRef,
  getCurrencySymbol,
  getRate,
  useExchangeRates,
  useGlobalSetting,
  useResearchRootContext,
} from "./ResearchList";

import { useTransRouterNavigate } from "../../trans-router/TransRouterNavigate";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import "./Research.scss";

export type ExchangeRate = {
  exchangeCurrencyCode: string;
  rate: number;
  date: Date;
};

type ProductPriceSort = {
  name: string;
};

export type ProductPrice = {
  id: string;
  name: string;
  price: number;
  currencyCode: CurrencyCode;
  source: string;
  date: Date;
  orgPrice: string;
  link?: string;
  settings?: {
    basePrice?: number;
    barelyAffordablePrice?: number;
    date?: string;
  };
};

export type ProductPriceResponse = {
  count: number;
  page: number;
  limit: number;
  sort: ProductPriceSort;
  results: ProductPrice[];
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

type TrendsResult = {
  _id: string;
  avg?: number;
  max?: number;
  min?: number;
};

type TrendsResponse = {
  from: string;
  to: string;
  results: TrendsResult[];
};

const ChartModal: FC = () => {
  const [trends, setTrends] = useState<TrendsResponse>();
  const [yearlyTrends, setYearlyTrends] = useState<TrendsResponse>();
  const [selectedMode, setSelectedMode] = useState<"THREE_MONTHS" | "YEARLY">(
    "THREE_MONTHS"
  );
  const {
    showChartModal,
    setShowChartModal,
    selectedProductPrice,
    setSelectedProductPrice,
  } = useContext(ResearchContext);
  const { httpClient } = useHttpClient();

  const handleClose = () => {
    setSelectedProductPrice(undefined);
    setShowChartModal(false);
  };

  useEffect(() => {
    if (!showChartModal) {
      setTrends(undefined);
      setYearlyTrends(undefined);
    }
    if (!showChartModal || !selectedProductPrice) {
      return;
    }

    if (selectedMode === "THREE_MONTHS" && !trends) {
      (async () => {
        const result = await httpClient.get<TrendsResponse>(
          `/api/v2/product-prices/${encodeURIComponent(
            selectedProductPrice.source
          )}/trends/${encodeURIComponent(selectedProductPrice.name)}`
        );
        setTrends(result.data);
      })().catch((err) => {
        console.error(err);
      });
    }
    if (selectedMode === "YEARLY" && !yearlyTrends) {
      (async () => {
        const result = await httpClient.get<TrendsResponse>(
          `/api/v2/product-prices/${encodeURIComponent(
            selectedProductPrice.source
          )}/trends/${encodeURIComponent(selectedProductPrice.name)}/yearly`
        );
        setYearlyTrends(result.data);
      })().catch((err) => {
        console.error(err);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpClient, showChartModal, selectedProductPrice, selectedMode]);

  const getData = () => {
    const targetTrends =
      selectedMode === "THREE_MONTHS" ? trends : yearlyTrends;
    if (!targetTrends) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const labels = targetTrends.results.map((result) => result._id);
    const data = {
      labels,
      datasets: [
        {
          label: "平均値",
          data: targetTrends.results.map((result) => result.avg),
          borderColor: "rgb(53, 235, 132)",
          backgroundColor: "rgba(53, 235, 132, 0.5)",
        },
        {
          label: "最高値",
          data: targetTrends.results.map((result) => result.max),
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          label: "最安値",
          data: targetTrends.results.map((result) => result.min),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };

    return data;
  };

  const options: {} = {
    maintainAspectRatio: false,
    responsive: true,
    spanGaps: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "日付",
        },
      },
      y: {
        title: {
          display: true,
          text: `価格 (${getCurrencySymbol(
            selectedProductPrice?.currencyCode || ""
          )})`,
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: selectedProductPrice?.name,
      },
    },
  };

  return (
    <Modal
      show={showChartModal}
      onHide={handleClose}
      fullscreen="lg-down"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>価格推移</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column h-100 gap-2">
          <div className="text-end">
            <ButtonGroup aria-label="select-mode">
              <Button
                variant="outline-secondary"
                active={selectedMode === "THREE_MONTHS"}
                onClick={() => setSelectedMode("THREE_MONTHS")}
              >
                直近3ヶ月
              </Button>
              <Button
                variant="outline-secondary"
                active={selectedMode === "YEARLY"}
                onClick={() => setSelectedMode("YEARLY")}
              >
                １年間
              </Button>
            </ButtonGroup>
          </div>
          {(trends || yearlyTrends) && (
            <div className="flex-fill">
              <Line
                height={400}
                data={getData()}
                options={options}
                id="chart-key"
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const MultiChartModal: FC = () => {
  const [trends, setTrends] = useState<TrendsResponse[]>();
  const [yearlyTrends, setYearlyTrends] = useState<TrendsResponse[]>();
  const [selectedMode, setSelectedMode] = useState<"THREE_MONTHS" | "YEARLY">(
    "THREE_MONTHS"
  );
  const [selectedMode2, setSelectedMode2] = useState<"AVG" | "MAX" | "MIN">(
    "AVG"
  );
  const {
    selectedProductPrices,
    setSelectedProductPrices,
    multiChartMode2,
    setMultiChartMode2,
  } = useContext(ResearchContext);
  const { httpClient } = useHttpClient();

  const handleClose = () => {
    setMultiChartMode2(false);
    setTrends(undefined);
    setYearlyTrends(undefined);
    setSelectedProductPrices({});
  };

  useEffect(() => {
    if (!multiChartMode2) {
      return;
    }

    if (selectedMode === "THREE_MONTHS") {
      if (trends) {
        return;
      }
      (async () => {
        const results: TrendsResponse[] = [];
        for (const price of Object.values(selectedProductPrices)) {
          const result = await httpClient.get<TrendsResponse>(
            `/api/v2/product-prices/${encodeURIComponent(
              price.source
            )}/trends/${encodeURIComponent(price.name)}`
          );
          results.push(result.data);
        }
        setTrends(results);
      })().catch((err) => {
        console.error(err);
      });
    } else {
      if (yearlyTrends) {
        return;
      }
      (async () => {
        const results: TrendsResponse[] = [];
        for (const price of Object.values(selectedProductPrices)) {
          const result = await httpClient.get<TrendsResponse>(
            `/api/v2/product-prices/${encodeURIComponent(
              price.source
            )}/trends/${encodeURIComponent(price.name)}/yearly`
          );
          results.push(result.data);
        }
        setYearlyTrends(results);
      })().catch((err) => {
        console.error(err);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpClient, multiChartMode2, selectedProductPrices, selectedMode]);

  const colorPatterns = [
    "#332288",
    "#88CCEE",
    "#44AA99",
    "#117733",
    "#999933",
    "#DDCC77",
    "#CC6677",
    "#882255",
    "#AA4499",
    "#AAAAAA",
  ];

  const getData = () => {
    const targetTrends =
      selectedMode === "THREE_MONTHS" ? trends : yearlyTrends;
    if (!targetTrends) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const labels = getLabels(targetTrends, selectedMode);
    const prices = Object.entries(selectedProductPrices);
    const data = {
      labels,
      datasets: targetTrends.map((trend, idx) => {
        return {
          label: prices[idx][1].name,
          data: labels.map((label) => {
            const targetResult = trend.results.filter(
              (result) => result._id === label
            );
            if (targetResult.length === 0) {
              return undefined;
            }
            switch (selectedMode2) {
              case "MAX":
                return targetResult[0].max;
              case "MIN":
                return targetResult[0].min;
              default:
                return targetResult[0].avg;
            }
          }),
          borderColor: colorPatterns[idx],
          backgroundColor: colorPatterns[idx] + "99",
        };
      }),
    };
    return data;
  };

  const options: {} = {
    maintainAspectRatio: false,
    responsive: true,
    spanGaps: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "日付",
        },
      },
      y: {
        title: {
          display: true,
          text:
            Object.keys(selectedProductPrices).length !== 0
              ? `価格 (${getCurrencySymbol(
                  Object.entries(selectedProductPrices)[0][1]?.currencyCode ||
                    ""
                )})`
              : "",
        },
      },
    },
  };

  return (
    <Modal
      show={multiChartMode2}
      onHide={handleClose}
      fullscreen="lg-down"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>複数商品の価格推移</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column h-100 gap-2">
          <div className="d-flex justify-content-between">
            <div className="">
              <ButtonGroup aria-label="select-mode">
                <Button
                  variant="outline-secondary"
                  size="sm"
                  active={selectedMode === "THREE_MONTHS"}
                  onClick={() => setSelectedMode("THREE_MONTHS")}
                >
                  直近3ヶ月
                </Button>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  active={selectedMode === "YEARLY"}
                  onClick={() => setSelectedMode("YEARLY")}
                >
                  １年間
                </Button>
              </ButtonGroup>
            </div>
            <div className="">
              <ButtonGroup aria-label="select-mode">
                <Button
                  variant="outline-secondary"
                  size="sm"
                  active={selectedMode2 === "AVG"}
                  onClick={() => setSelectedMode2("AVG")}
                >
                  平均
                </Button>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  active={selectedMode2 === "MAX"}
                  onClick={() => setSelectedMode2("MAX")}
                >
                  最大
                </Button>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  active={selectedMode2 === "MIN"}
                  onClick={() => setSelectedMode2("MIN")}
                >
                  最小
                </Button>
              </ButtonGroup>
            </div>
          </div>
          {(trends || yearlyTrends) && (
            <div className="flex-fill">
              <Line
                height={400}
                data={getData()}
                options={options}
                id="chart-key"
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const GlobalSettingModal: FC = () => {
  const {
    showGlobalSettingModal,
    setShowGlobalSettingModal,
    priceCoefficient,
    bankFee,
    updateGlobalSetting,
  } = useContext(ResearchContext);
  const [inputPriceCoefficient, setInputPriceCoefficient] = useState("");
  const [inputBankFee, setInputBankFee] = useState("");

  useEffect(() => {
    if (!showGlobalSettingModal) {
      return;
    }
    setInputPriceCoefficient(priceCoefficient.toString());
    setInputBankFee(bankFee.toString());
  }, [priceCoefficient, bankFee, showGlobalSettingModal]);

  const handleClose = () => {
    setShowGlobalSettingModal(false);
  };

  return (
    <Modal show={showGlobalSettingModal} onHide={handleClose}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          let priceCoefficient = 1;
          if (inputPriceCoefficient) {
            priceCoefficient = +inputPriceCoefficient;
          }
          let bankFee = 1;
          if (inputBankFee) {
            bankFee = +inputBankFee;
          }
          await updateGlobalSetting({ priceCoefficient, bankFee });
          setShowGlobalSettingModal(false);
        }}
        noValidate
      >
        <Modal.Header closeButton>
          <Modal.Title>設定</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>価格係数</Form.Label>
            <Form.Control
              type="number"
              placeholder="例: 1.3"
              autoFocus
              value={inputPriceCoefficient}
              onChange={(e) => {
                setInputPriceCoefficient(e.target.value);
              }}
            />
            <Form.Text className="text-muted">
              各商品価格にかける割合です。関税などの必要経費を上乗せしたざっくりな商品価格にしたい場合に利用します。省略時は{" "}
              <b>1</b> となります。
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>銀行手数料</Form.Label>
            <Form.Control
              type="number"
              placeholder="例: 1.2"
              autoFocus
              value={inputBankFee}
              onChange={(e) => {
                setInputBankFee(e.target.value);
              }}
            />
            <Form.Text className="text-muted">
              価格計数を反映した商品価格にかける割合です。銀行手数料を上乗せしたざっくりな商品価格にしたい場合に利用します。省略時は{" "}
              <b>1</b> となります。
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            閉じる
          </Button>
          <Button type="submit" variant="primary">
            保存
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const ProductPriceSettingModal: FC = () => {
  const {
    showProductPriceSettingModal,
    setShowProductPriceSettingModal,
    selectedProductPrice,
    setSelectedProductPrice,
    getRate,
    productPriceSettings,
    updateProductPriceSettings,
    deleteProductPriceSettings,
    priceCoefficient,
    bankFee,
  } = useContext(ResearchContext);
  const [basePrice, setBasePrice] = useState("");
  const [barelyAffordablePrice, setBarelyAffordablePrice] = useState("");
  const [date, setDate] = useState("-");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    if (!showProductPriceSettingModal || !selectedProductPrice) {
      return;
    }
    const selectedSetting = productPriceSettings[selectedProductPrice.name];
    if (!selectedSetting) {
      setBasePrice("");
      setBarelyAffordablePrice("");
      setDate("-");
      return;
    }
    setBasePrice(
      selectedSetting.basePrice !== undefined
        ? selectedSetting.basePrice.toString()
        : ""
    );
    setBarelyAffordablePrice(
      selectedSetting.barelyAffordablePrice !== undefined
        ? selectedSetting.barelyAffordablePrice.toString()
        : ""
    );
    setDate(
      selectedSetting.date !== undefined ? selectedSetting.date.toString() : ""
    );
  }, [
    productPriceSettings,
    selectedProductPrice,
    showProductPriceSettingModal,
  ]);

  const handleClose = () => {
    setSelectedProductPrice(undefined);
    setShowProductPriceSettingModal(false);
  };

  const getPriceJPY = () => {
    if (!selectedProductPrice) {
      return 0;
    }
    return Math.floor(
      (getRate(selectedProductPrice.currencyCode + "JPY") || 0) *
        selectedProductPrice.price *
        priceCoefficient *
        bankFee
    );
  };

  if (!selectedProductPrice) {
    return null;
  }

  return (
    <>
      <Modal show={showProductPriceSettingModal} onHide={handleClose}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (basePrice || barelyAffordablePrice) {
              updateProductPriceSettings(
                [
                  {
                    name: selectedProductPrice.name,
                    basePrice: basePrice ? +basePrice : null,
                    barelyAffordablePrice: barelyAffordablePrice
                      ? +barelyAffordablePrice
                      : null,
                  },
                ],
                true
              );
            } else {
              await deleteProductPriceSettings([selectedProductPrice.name]);
            }
            setSelectedProductPrice(undefined);
            setShowProductPriceSettingModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>通知設定</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>商品名</Form.Label>
              <Form.Control
                type="text"
                value={selectedProductPrice.name}
                plaintext={true}
                readOnly={true}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>価格</Form.Label>
              <Form.Control
                type="text"
                value={`${getCurrencySymbol(
                  "JPY"
                )} ${getPriceJPY().toLocaleString()} (${getCurrencySymbol(
                  selectedProductPrice.currencyCode
                )} ${selectedProductPrice.price.toLocaleString()})`}
                plaintext={true}
                readOnly={true}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>基準仕入れ価格</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>{getCurrencySymbol("JPY")}</InputGroup.Text>
                <Form.Control
                  type="tel"
                  placeholder={`例: ${getPriceJPY() + 1}`}
                  // autoFocus
                  value={basePrice}
                  onChange={(e) => {
                    setBasePrice(e.target.value);
                  }}
                />
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    setBasePrice((getPriceJPY() + 1).toString());
                  }}
                >
                  <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                </Button>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>ギリギリ仕入れ価格</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>{getCurrencySymbol("JPY")}</InputGroup.Text>
                <Form.Control
                  type="tel"
                  placeholder={`例: ${getPriceJPY() + 2}`}
                  value={barelyAffordablePrice}
                  onChange={(e) => {
                    setBarelyAffordablePrice(e.target.value);
                  }}
                />
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    setBarelyAffordablePrice((getPriceJPY() + 2).toString());
                  }}
                >
                  <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                </Button>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>更新日</Form.Label>
              {date === "-" ? (
                <div className="mb-3 form-control-plaintext text-muted">-</div>
              ) : (
                <div className="mb-3 form-control-plaintext">
                  {date ? moment(date).format("YYYY/MM/DD HH:mm") : "不明"}
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <div>
              <Button
                variant="danger"
                onClick={async () => {
                  setShowConfirmModal(true);
                }}
                disabled={!productPriceSettings[selectedProductPrice.name]}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
            <div className="d-flex gap-2">
              <Button variant="secondary" onClick={handleClose}>
                閉じる
              </Button>
              <Button type="submit" variant="primary">
                保存
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      <ConfirmDeleteModal
        showConfirmModal={showConfirmModal}
        handleClose={() => {
          setShowConfirmModal(false);
        }}
        handleDone={() => {
          (async () => {
            await deleteProductPriceSettings([selectedProductPrice.name]);
            setShowConfirmModal(false);
            handleClose();
          })().catch((err) => {
            console.error(err);
          });
        }}
      />
    </>
  );
};

const ResearchContext = createContext<{
  selectedSource: string;
  showChartModal: boolean;
  setShowChartModal: Dispatch<SetStateAction<boolean>>;
  showProductPriceSettingModal: boolean;
  setShowProductPriceSettingModal: Dispatch<SetStateAction<boolean>>;
  showGlobalSettingModal: boolean;
  setShowGlobalSettingModal: Dispatch<SetStateAction<boolean>>;
  selectedProductPrice?: ProductPrice;
  setSelectedProductPrice: (_price?: ProductPrice) => void;
  priceCoefficient: number;
  bankFee: number;
  updateGlobalSetting: ({
    priceCoefficient,
    bankFee,
  }: {
    priceCoefficient?: number | undefined;
    bankFee?: number | undefined;
  }) => Promise<void>;
  getRate: (code: string) => number | undefined;
  productPriceSettings: {
    [name: string]: {
      basePrice?: number;
      barelyAffordablePrice?: number;
      date?: Date;
    };
  };
  updateProductPriceSettings: (
    productPriceSettings: {
      name: string;
      basePrice?: number | null;
      barelyAffordablePrice?: number | null;
    }[],
    persistence?: boolean
  ) => void;
  deleteProductPriceSettings: (names: string[]) => Promise<void>;
  selectedProductPrices: { [id: string]: ProductPrice };
  setSelectedProductPrices: Dispatch<
    SetStateAction<{ [id: string]: ProductPrice }>
  >;
  multiChartMode: "READY" | "SELECTED";
  setMultiChartMode: Dispatch<SetStateAction<"READY" | "SELECTED">>;
  multiChartMode2: boolean;
  setMultiChartMode2: Dispatch<SetStateAction<boolean>>;
}>({
  selectedSource: "cigar-shop-world",
  showChartModal: false,
  setShowChartModal: (_) => {},
  showProductPriceSettingModal: false,
  setShowProductPriceSettingModal: (_) => {},
  showGlobalSettingModal: false,
  setShowGlobalSettingModal: (_) => {},
  setSelectedProductPrice: (_) => {},
  priceCoefficient: 1.0,
  bankFee: 1.0,
  updateGlobalSetting: async () => {},
  getRate: (_) => {
    return undefined;
  },
  productPriceSettings: {},
  updateProductPriceSettings: (_) => {},
  deleteProductPriceSettings: async (_) => {},
  selectedProductPrices: {},
  setSelectedProductPrices: (_) => {},
  multiChartMode: "READY",
  setMultiChartMode: (_) => {},
  multiChartMode2: false,
  setMultiChartMode2: (_) => {},
});

type TrendSet = {
  id: string;
  name: string;
  products: { name: string; currencyCode: CurrencyCode }[];
  source: string;
  userId: string;
  updateOn: string;
};

const Research: FC = () => {
  const { navigateIndent } = useTransRouterNavigate();
  const {
    source: selectedSource,
    setSource: setSelectedSource,
    sort: selectedSort,
    setSort: setSelectedSort,
  } = useResearchRootContext();
  const {
    priceCoefficient,
    bankFee,
    update: updateGlobalSetting,
  } = useGlobalSetting();
  const { exchangeRates, update: updateExchangeRates } = useExchangeRates();
  const researchListRef = useRef<ResearchRef>();
  const [selectedProductPrice, setSelectedProductPrice] =
    useState<ProductPrice>();
  const [showChartModal, setShowChartModal] = useState(false);
  const [showProductPriceSettingModal, setShowProductPriceSettingModal] =
    useState(false);
  const [showGlobalSettingModal, setShowGlobalSettingModal] = useState(false);
  const { httpClient } = useHttpClient();
  const { user } = useUser();
  const [multiChartMode, setMultiChartMode] = useState<"READY" | "SELECTED">(
    "READY"
  );
  const [multiChartMode2, setMultiChartMode2] = useState(false);
  const [selectedProductPrices, setSelectedProductPrices] = useState<{
    [id: string]: ProductPrice;
  }>({});
  const [selectedListMode, setSelectedListMode] = useState<"SET" | "DIRECT">(
    "SET"
  );
  const [trendSets, setTrendSets] = useState<TrendSet[]>([]);
  const [selectedTrendSet, setSelectedTrendSet] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (multiChartMode === "READY") {
      setTrendSets([]);
      setSelectedTrendSet(undefined);
      return;
    }
    if (selectedListMode === "DIRECT") {
      setSelectedTrendSet(undefined);
      setSelectedProductPrices({});
      return;
    }
    if (!user) {
      return;
    }
    (async () => {
      const { data } = await httpClient.get<TrendSet[]>(
        `/api/v2/trend-sets/${user.payload.id}/${selectedSource}`,
        { params: { existsproduct: "true" } }
      );
      setTrendSets(data);
      if (data.length !== 0) {
        setSelectedTrendSet(data[0].id);
      }
      setSelectedProductPrices({});
    })().catch((err) => {
      console.error(err);
    });
  }, [user, selectedSource, multiChartMode, selectedListMode, httpClient]);

  const reset = () => {
    updateExchangeRates();
    researchListRef.current?.reset();
  };

  const getRateDate = (code: string) => {
    if (!exchangeRates) {
      return undefined;
    }
    const filtered = exchangeRates.filter(
      (rate) => rate.exchangeCurrencyCode === code
    );
    if (filtered.length === 0) {
      return undefined;
    }
    return filtered[0].date;
  };

  if (!exchangeRates) {
    return null;
  }

  const maxNum = 10;
  const disabled = Object.keys(selectedProductPrices).length >= maxNum;
  const enabled =
    selectedListMode === "SET"
      ? selectedTrendSet
      : Object.keys(selectedProductPrices).length > 0;

  return (
    <>
      <TransRouterMotionSection className="Research">
        <ResearchContext.Provider
          value={{
            selectedSource,
            showChartModal,
            setShowChartModal,
            showProductPriceSettingModal,
            setShowProductPriceSettingModal,
            showGlobalSettingModal,
            setShowGlobalSettingModal,
            selectedProductPrice,
            setSelectedProductPrice,
            priceCoefficient,
            bankFee,
            updateGlobalSetting,
            getRate: (code: string) => getRate(code, exchangeRates),
            productPriceSettings: researchListRef.current
              ? researchListRef.current.getProductPriceSettings()
              : {},
            updateProductPriceSettings: researchListRef.current
              ? researchListRef.current.updateProductPriceSettings
              : (_) => {},
            deleteProductPriceSettings: researchListRef.current
              ? researchListRef.current.deleteProductPriceSettings
              : async (_) => {},
            selectedProductPrices,
            setSelectedProductPrices,
            multiChartMode,
            setMultiChartMode,
            multiChartMode2,
            setMultiChartMode2,
          }}
        >
          <Container
            className={multiChartMode === "READY" ? "" : "multi-chart-mode"}
          >
            <h2 className="h5 mb-3">商品価格一覧</h2>
            <div className="d-flex justify-content-between">
              <div></div>
              <div className="d-flex">
                <div className="me-2">
                  <Form.Select
                    size="sm"
                    value={selectedSort}
                    onChange={async (e) => {
                      setSelectedSort(e.target.value as Sort);
                      reset();
                    }}
                    disabled={multiChartMode !== "READY"}
                  >
                    <option value="PriceAsc">価格安い順</option>
                    <option value="PriceDesc">価格高い順</option>
                    <option value="NameAsc">名前昇順</option>
                    <option value="NameDesc">名前降順</option>
                  </Form.Select>
                </div>
                <div>
                  <SourceSelect
                    className="mb-1"
                    value={selectedSource}
                    onChange={async (value) => {
                      setSelectedSource(value);
                      reset();
                    }}
                    disabled={multiChartMode !== "READY"}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mb-1">
              <div>
                <div>
                  <small>
                    {getCurrencySymbol("EUR")}1 = {getCurrencySymbol("JPY")}
                    {getRate("EURJPY", exchangeRates)} (
                    {moment(getRateDate("EURJPY")).format("YYYY/MM/DD HH:mm")})
                  </small>
                </div>
                <div>
                  <small>
                    {getCurrencySymbol("USD")}1 = {getCurrencySymbol("JPY")}
                    {getRate("USDJPY", exchangeRates)} (
                    {moment(getRateDate("USDJPY")).format("YYYY/MM/DD HH:mm")})
                  </small>
                </div>
                <div>
                  <small>価格係数 = {priceCoefficient}</small>
                </div>
                <div>
                  <small>銀行手数料 = {bankFee}</small>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={async () => {
                      if (multiChartMode === "READY") {
                        setMultiChartMode("SELECTED");
                      } else if (multiChartMode === "SELECTED") {
                        setMultiChartMode("READY");
                        setSelectedProductPrices({});
                      }
                    }}
                    className="me-1"
                  >
                    <FontAwesomeIcon icon={faChartLine} />
                  </Button>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={async () => {
                      updateExchangeRates();
                      researchListRef.current?.reset();
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowsRotate} />
                  </Button>
                </div>
                <Dropdown className="config">
                  <Dropdown.Toggle
                    variant="link"
                    disabled={multiChartMode !== "READY"}
                  >
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#"
                      onClick={() => {
                        setShowGlobalSettingModal(true);
                      }}
                    >
                      設定追加
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => {
                        navigateIndent("/modify-set");
                      }}
                    >
                      価格推移セット修正
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <ResearchList
              sort={selectedSort}
              source={selectedSource}
              ref={researchListRef}
              Children={({ price, productPriceSettings }) => {
                return (
                  <>
                    <div className="d-flex justify-content-between align-items-start flex-column flex-sm-row flex-grow-1">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">{price.name}</div>
                        <div>
                          <BadgeIsSetting
                            price={price}
                            productPriceSettings={productPriceSettings}
                          />
                          <BadgeGoodTimeToBuy
                            price={price}
                            productPriceSettings={productPriceSettings}
                            exchangeRates={exchangeRates}
                            priceCoefficient={priceCoefficient}
                            bankFee={bankFee}
                          />
                        </div>
                        <div className="d-md-flex gap-3">
                          <div>
                            <small>
                              <FontAwesomeIcon
                                icon={faClock}
                                className="me-1"
                              />
                              {moment(price.date).format("YYYY/MM/DD HH:mm")}
                            </small>
                          </div>
                          {productPriceSettings[price.name] && (
                            <div>
                              <small>
                                <FontAwesomeIcon
                                  icon={faBell}
                                  className="me-1"
                                />
                                {productPriceSettings[price.name].date
                                  ? moment(
                                      productPriceSettings[price.name].date
                                    ).format("YYYY/MM/DD HH:mm")
                                  : "不明"}
                              </small>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="h-100 ms-2 d-flex justify-content-center align-items-center flex-row flex-sm-column flex-md-row text-nowrap text-end">
                        <div>
                          {getCurrencySymbol("JPY")}{" "}
                          {Math.floor(
                            price.price *
                              (getRate(
                                price.currencyCode + "JPY",
                                exchangeRates
                              ) || 0) *
                              priceCoefficient *
                              bankFee
                          ).toLocaleString()}
                        </div>
                        <div className="ms-2 d-sm-none d-md-block"></div>
                        <div>
                          ({getCurrencySymbol(price.currencyCode)}{" "}
                          {price.price.toLocaleString()})
                        </div>
                      </div>
                    </div>
                    {multiChartMode === "SELECTED" &&
                    selectedListMode === "DIRECT" ? (
                      <div className="ms-3 align-self-center">
                        <FormCheck
                          checked={Object.keys(selectedProductPrices).includes(
                            price.id
                          )}
                          onChange={(e) => {
                            setSelectedProductPrices((val) => {
                              const newVal = { ...val };
                              if (Object.keys(newVal).includes(price.id)) {
                                delete newVal[price.id];
                              } else {
                                newVal[price.id] = price;
                              }
                              return newVal;
                            });
                          }}
                          disabled={
                            !Object.keys(selectedProductPrices).includes(
                              price.id
                            ) && disabled
                          }
                        ></FormCheck>
                      </div>
                    ) : (
                      <div className="ms-1">
                        <Dropdown className="config config-product-price h-100 d-sm-flex">
                          <Dropdown.Toggle
                            variant="link"
                            disabled={multiChartMode === "SELECTED"}
                          >
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              href="#"
                              onClick={() => {
                                setSelectedProductPrice(price);
                                setShowProductPriceSettingModal(true);
                              }}
                            >
                              通知設定
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedProductPrice(price);
                                setShowChartModal(true);
                              }}
                            >
                              価格推移
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={!price.link}
                              onClick={() => {
                                window.open(
                                  price.link,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }}
                            >
                              サイトへ
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  </>
                );
              }}
            />
          </Container>
          <ChartModal />
          <MultiChartModal />
          <GlobalSettingModal />
          <ProductPriceSettingModal />
        </ResearchContext.Provider>
      </TransRouterMotionSection>
      {multiChartMode === "SELECTED" && (
        <footer className="footer fixed-bottom bg-light pt-2 pb-2">
          <div className="container d-flex justify-content-between gap-2">
            <div className="align-self-center">
              <ButtonGroup aria-label="select-mode">
                <Button
                  size="sm"
                  variant="outline-secondary"
                  active={selectedListMode === "SET"}
                  onClick={() => setSelectedListMode("SET")}
                >
                  <FontAwesomeIcon icon={faList} />
                </Button>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  active={selectedListMode === "DIRECT"}
                  onClick={() => setSelectedListMode("DIRECT")}
                >
                  <FontAwesomeIcon icon={faListCheck} />
                </Button>
              </ButtonGroup>
            </div>
            {selectedListMode === "SET" ? (
              <>
                <div className="align-self-center flex-grow-1">
                  <div className="wm-20 ms-auto">
                    {trendSets.length === 0 ? (
                      <Form.Select
                        aria-label="Default select example"
                        size="sm"
                        disabled={true}
                      >
                        <option>セットなし</option>
                      </Form.Select>
                    ) : (
                      <Form.Select
                        aria-label="Default select example"
                        size="sm"
                        value={selectedTrendSet}
                        onChange={(e) => {
                          const id = e.target.value;
                          setSelectedTrendSet(id);
                        }}
                      >
                        {trendSets.map(({ id, name }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="align-self-center flex-grow-1 text-end">
                  {!enabled && (
                    <span className="small text-danger">
                      1つ以上選択してください
                    </span>
                  )}
                  {disabled && (
                    <span className="small text-danger">
                      {maxNum}つまでしか選択できません
                    </span>
                  )}
                </div>
              </>
            )}
            <div className="align-self-center d-flex gap-1">
              <Button
                size="sm"
                variant="secondary"
                className="btn-icon"
                onClick={() => {
                  setMultiChartMode("READY");
                  setSelectedProductPrices({});
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </Button>
              <Button
                size="sm"
                variant="secondary"
                className="btn-icon"
                onClick={() => {
                  if (selectedListMode === "SET") {
                    const { products, source } = trendSets.filter(
                      (set) => set.id === selectedTrendSet
                    )[0];
                    setSelectedProductPrices(
                      products.reduce(
                        (accumulator, product, index) => ({
                          ...accumulator,
                          [selectedTrendSet + "_" + index]: {
                            id: selectedTrendSet + "_" + index,
                            ...product,
                            source: source,
                          },
                        }),
                        {}
                      )
                    );
                  }
                  setMultiChartMode2(true);
                }}
                disabled={!enabled}
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

function getDayTermStrs(start: string, end: string) {
  const cursorDate = moment(start + "T00:00:00.000+09:00").tz("Asia/Tokyo");
  const endDate = moment(end + "T00:00:00.000+09:00").tz("Asia/Tokyo");
  const dates: string[] = [];
  while (endDate.diff(cursorDate) >= 0) {
    dates.push(cursorDate.format("YYYY-MM-DD"));
    cursorDate.add(1, "d");
  }
  return dates;
}

function getMonthTermStrs(start: string, end: string) {
  const cursorDate = moment(start + "-01T00:00:00.000+09:00").tz("Asia/Tokyo");
  const endDate = moment(end + "-01T00:00:00.000+09:00").tz("Asia/Tokyo");
  const monthes: string[] = [];
  while (endDate.diff(cursorDate) >= 0) {
    monthes.push(cursorDate.format("YYYY-MM"));
    cursorDate.add(1, "month");
  }
  return monthes;
}

function getLabels(
  targetTrends: TrendsResponse[],
  selectedMode: "THREE_MONTHS" | "YEARLY"
) {
  let minFrom: Moment | undefined = undefined;
  let maxTo: Moment | undefined = undefined;
  for (const targetTrend of targetTrends) {
    if (targetTrend.results.length === 0) {
      continue;
    }
    const fromStr = targetTrend.results[0]._id;
    const fromTok = fromStr.split("-");
    const from =
      selectedMode === "THREE_MONTHS"
        ? moment(`${fromTok[0]}-${fromTok[1]}-${fromTok[2]}`)
        : moment(`${fromTok[0]}-${fromTok[1]}-01`);
    if (!minFrom) {
      minFrom = from;
    } else {
      if (minFrom.diff(from) > 0) {
        minFrom = from;
      }
    }
    const toStr = targetTrend.results[targetTrend.results.length - 1]._id;
    const toTok = toStr.split("-");
    const to =
      selectedMode === "THREE_MONTHS"
        ? moment(`${toTok[0]}-${toTok[1]}-${toTok[2]}`)
        : moment(`${toTok[0]}-${toTok[1]}-01`);
    if (!maxTo) {
      maxTo = to;
    } else {
      if (maxTo.diff(to) < 0) {
        maxTo = to;
      }
    }
  }
  if (!minFrom || !maxTo) {
    return [];
  }
  return selectedMode === "THREE_MONTHS"
    ? getDayTermStrs(minFrom!.format("YYYY-MM-DD"), maxTo!.format("YYYY-MM-DD"))
    : getMonthTermStrs(minFrom!.format("YYYY-MM"), maxTo!.format("YYYY-MM"));
}

export default Research;
