import {
  faBell,
  faFaceGrimace,
  faFaceSmileBeam,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isAxiosError } from "axios";
import {
  Dispatch,
  ElementType,
  FC,
  ForwardRefRenderFunction,
  ReactNode,
  SetStateAction,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Badge, ListGroup } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";

import { Sort, Source } from "../../def";
import { useHttpClient } from "../../http-client/HttpClient";
import { useUser } from "../../user/User";
import { ExchangeRate, ProductPrice, ProductPriceResponse } from "./Research";

export type ResearchRef =
  | {
      reset: () => void;
      getProductPriceSettings: () => ProductPriceSetting;
      updateProductPriceSettings: (
        productPriceSettings: {
          name: string;
          basePrice?: number | null;
          barelyAffordablePrice?: number | null;
        }[],
        persistence?: boolean
      ) => void;
      deleteProductPriceSettings: (names: string[]) => Promise<void>;
    }
  | undefined;

type ProductPriceSetting = {
  [name: string]: {
    basePrice?: number;
    barelyAffordablePrice?: number;
    date?: Date;
  };
};

type UpdateProductPriceSettingsResult = {
  userId: string;
  productName: string;
  body: UpdateProductPriceSettingsResultBody;
};

type UpdateProductPriceSettingsResultBody = {
  basePrice: number;
  barelyAffordablePrice: number;
  date?: string;
};

const ResearchListBase: ForwardRefRenderFunction<
  ResearchRef,
  {
    sort: Sort;
    source: string;
    lgAs?: ElementType;
    lgiAs?: ElementType;
    Children: LGIChild;
  }
> = ({ sort, source, lgAs = "ol", lgiAs = "li", Children }, ref) => {
  const { httpClient } = useHttpClient();
  const { user } = useUser();
  const [hasMore, setHasMore] = useState(true);
  const [productPrices, setProductPrices] = useState<ProductPrice[]>([]);
  const [productPriceSettings, setProductPriceSettings] =
    useState<ProductPriceSetting>({});
  const [updateCounter, setUpdateCounter] = useState(0);

  useImperativeHandle(ref, () => ({
    reset() {
      setProductPrices([]);
      setProductPriceSettings({});
      if (!hasMore) {
        setHasMore(true);
      }
      setUpdateCounter((counter) => counter + 1);
    },
    getProductPriceSettings: () => {
      return productPriceSettings;
    },
    updateProductPriceSettings,
    deleteProductPriceSettings,
  }));

  const loader = (
    <div className="text-center" key={0}>
      Loading ...
    </div>
  );

  const updateProductPriceSettings = async (
    productPriceSettings: {
      name: string;
      basePrice?: number | null;
      barelyAffordablePrice?: number | null;
      date?: Date;
    }[],
    persistence = false
  ) => {
    const newProductPriceSettings = productPriceSettings.map(
      ({ name, basePrice, barelyAffordablePrice, date }) => ({
        name,
        basePrice,
        barelyAffordablePrice,
        date,
      })
    );
    if (persistence) {
      for (const [
        number,
        { name, basePrice, barelyAffordablePrice },
      ] of newProductPriceSettings.entries()) {
        const {
          data: {
            body: { date },
          },
        } = await httpClient.put<UpdateProductPriceSettingsResult>(
          `/api/v2/product-price-settings/${
            user?.payload.id
          }/${source}/${encodeURIComponent(name)}`,
          {
            basePrice: basePrice ? +basePrice : null,
            barelyAffordablePrice: barelyAffordablePrice
              ? +barelyAffordablePrice
              : null,
          },
          {
            params: {
              update: "true", // TODO: 次のバージョンで消したい
            },
          }
        );
        if (date) {
          newProductPriceSettings[number].date = new Date(date);
        }
      }
    }
    setProductPriceSettings((settings) => {
      const newSettings = { ...settings };
      newProductPriceSettings.forEach(
        ({ name, basePrice, barelyAffordablePrice, date }) => {
          newSettings[name] = {
            basePrice:
              basePrice === undefined || basePrice === null
                ? undefined
                : basePrice,
            barelyAffordablePrice:
              barelyAffordablePrice === undefined ||
              barelyAffordablePrice === null
                ? undefined
                : barelyAffordablePrice,
            date,
          };
        }
      );
      return newSettings;
    });
  };

  const deleteProductPriceSettings = async (names: string[]) => {
    for (const name of names) {
      await httpClient.delete(
        `/api/v2/product-price-settings/${
          user?.payload.id
        }/${source}/${encodeURIComponent(name)}`
      );
    }
    setProductPriceSettings((val) => {
      const newVal = { ...val };
      for (const name of names) {
        delete newVal[name];
      }
      return newVal;
    });
  };

  const getProductPrices = async (page: number) => {
    let sortName: string | undefined = undefined;
    let sortPrice: string | undefined = undefined;
    switch (sort) {
      case "PriceAsc":
        sortPrice = "asc";
        break;
      case "PriceDesc":
        sortPrice = "desc";
        break;
      case "NameAsc":
        sortName = "asc";
        break;
      case "NameDesc":
        sortName = "desc";
        break;
    }
    const queries = [`page=${page}`, `limit=${20}`];
    if (sortName) {
      queries.push(`sortName=${sortName}`);
    }
    if (sortPrice) {
      queries.push(`sortPrice=${sortPrice}`);
    }
    const result = await httpClient.get<ProductPriceResponse>(
      `/api/v2/product-prices/${source}?${queries.join("&")}`
    );
    if (result.data.results.length < 1) {
      setHasMore(false);
      return;
    }
    const productPriceSettings: {
      name: string;
      basePrice?: number | null;
      barelyAffordablePrice?: number | null;
      date?: Date;
    }[] = [];
    setProductPrices((prices) => {
      const filterdResults: ProductPrice[] = [];
      result.data.results.forEach((result) => {
        if (
          prices.filter((price) => price.name === result.name).length === 0 &&
          filterdResults.filter((price) => price.id === result.id).length === 0
        ) {
          filterdResults.push(result);
        }
        if (result.settings) {
          productPriceSettings.push({
            name: result.name,
            basePrice: result.settings.basePrice,
            barelyAffordablePrice: result.settings.barelyAffordablePrice,
            date: result.settings.date
              ? new Date(result.settings.date)
              : undefined,
          });
        }
      });
      return [...prices, ...filterdResults];
    });
    updateProductPriceSettings(productPriceSettings);
  };

  return (
    <InfiniteScroll
      loadMore={getProductPrices}
      hasMore={hasMore}
      loader={loader}
      key={updateCounter}
    >
      <ListGroup as={lgAs} numbered>
        {productPrices.map((price, idx) => (
          <ListGroup.Item key={idx} as={lgiAs} className="d-flex">
            <Children
              price={price}
              productPriceSettings={productPriceSettings}
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </InfiniteScroll>
  );
};

export const ResearchList = forwardRef(ResearchListBase);

type LGIChild = FC<{
  price: ProductPrice;
  productPriceSettings: ProductPriceSetting;
}>;

export function getCurrencySymbol(code: string) {
  switch (code) {
    case "EUR":
      return "€";
    case "USD":
      return "$";
    case "JPY":
      return "¥";
    default:
      return "";
  }
}

export function getSettingBasePrice(
  name: string,
  productPriceSettings: ProductPriceSetting
) {
  if (
    !productPriceSettings[name] ||
    productPriceSettings[name].basePrice === undefined
  ) {
    return 0;
  }
  return productPriceSettings[name].basePrice;
}

export function getSettingBarelyAffordablePrice(
  name: string,
  productPriceSettings: ProductPriceSetting
) {
  if (
    !productPriceSettings[name] ||
    productPriceSettings[name].barelyAffordablePrice === undefined
  ) {
    return 0;
  }
  return productPriceSettings[name].barelyAffordablePrice;
}

export function getRate(code: string, exchangeRates: ExchangeRate[]) {
  if (!exchangeRates) {
    return undefined;
  }
  const filtered = exchangeRates.filter(
    (rate) => rate.exchangeCurrencyCode === code
  );
  if (filtered.length === 0) {
    return undefined;
  }
  return filtered[0].rate;
}

export const BadgeIsSetting: FC<{
  price: ProductPrice;
  productPriceSettings: ProductPriceSetting;
}> = ({ price, productPriceSettings }) => {
  const basePrice = getSettingBasePrice(price.name, productPriceSettings);
  const berelyAffordablePrice = getSettingBarelyAffordablePrice(
    price.name,
    productPriceSettings
  );
  if (!basePrice && !berelyAffordablePrice) {
    return null;
  }
  return (
    <Badge bg="primary">
      通知設定あり
      <FontAwesomeIcon icon={faBell} className="ms-1" />
    </Badge>
  );
};

export const BadgeGoodTimeToBuy: FC<{
  price: ProductPrice;
  productPriceSettings: ProductPriceSetting;
  exchangeRates: ExchangeRate[];
  priceCoefficient?: number;
  bankFee?: number;
}> = ({
  price,
  productPriceSettings,
  exchangeRates,
  priceCoefficient = 1,
  bankFee = 1,
}) => {
  const priceJpy = Math.floor(
    price.price *
      (getRate(price.currencyCode + "JPY", exchangeRates) || 0) *
      priceCoefficient *
      bankFee
  );
  if (!priceJpy) {
    return null;
  }
  const basePrice = getSettingBasePrice(price.name, productPriceSettings);

  if (basePrice) {
    const diffBasePrice = basePrice - priceJpy;
    if (diffBasePrice > 0) {
      return (
        <Badge bg="danger" className="ms-1">
          買い
          <FontAwesomeIcon icon={faFaceSmileBeam} className="ms-1 me-1" />(
          {diffBasePrice.toLocaleString()}円お得)
        </Badge>
      );
    }
  }
  const barelyAffordablePrice = getSettingBarelyAffordablePrice(
    price.name,
    productPriceSettings
  );
  if (!barelyAffordablePrice) {
    return null;
  }
  const diffBarelyAffordablePrice = barelyAffordablePrice - priceJpy;
  if (diffBarelyAffordablePrice > 0) {
    return (
      <Badge bg="warning" className="ms-1">
        ギリギリ買い？
        <FontAwesomeIcon icon={faFaceGrimace} className="ms-1 me-1" />(
        {diffBarelyAffordablePrice.toLocaleString()}円お得)
      </Badge>
    );
  }
  return null;
};

export function useExchangeRates() {
  const [exchangeRates, setExchangeRates] = useState<ExchangeRate[]>([]);
  const [updateCnt, setUpdateCnt] = useState(0);
  const { httpClient } = useHttpClient();
  useEffect(() => {
    (async () => {
      const result = await httpClient.get<ExchangeRate[]>(
        "/api/v2/exchange-rates"
      );
      setExchangeRates([
        ...result.data,
        { exchangeCurrencyCode: "JPYJPY", rate: 1, date: new Date() },
      ]);
    })().catch((err) => {
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCnt]);
  return {
    exchangeRates,
    update: () => {
      setUpdateCnt((cnt) => cnt + 1);
    },
  };
}

export function useGlobalSetting() {
  const [priceCoefficient, setPriceCoefficient] = useState(1);
  const [bankFee, setBankFee] = useState(1);
  const { httpClient } = useHttpClient();
  const { user } = useUser();

  useEffect(() => {
    (async () => {
      try {
        const result = await httpClient.get<{
          id: string;
          priceCoefficient: number;
          bankFee: number;
          userId: string;
        }>(`/api/v2/global-settings/${user?.payload.id}`);
        setPriceCoefficient(result.data.priceCoefficient);
        setBankFee(result.data.bankFee);
      } catch (e) {
        if (!isAxiosError(e) || e.response?.status !== 404) {
          throw e;
        }
        setPriceCoefficient(1);
        setBankFee(1);
      }
    })().catch((err) => {
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    priceCoefficient,
    bankFee,
    update: async ({
      priceCoefficient,
      bankFee,
    }: {
      priceCoefficient?: number;
      bankFee?: number;
    }) => {
      await httpClient.put(`/api/v2/global-settings/${user?.payload.id}`, {
        priceCoefficient: priceCoefficient || 1,
        bankFee: bankFee || 1,
      });
      setPriceCoefficient(priceCoefficient || 1);
      setBankFee(bankFee || 1);
    },
  };
}

const ResearchRootContext = createContext<{
  sort: Sort;
  setSort: Dispatch<SetStateAction<Sort>>;
  source: Source;
  setSource: Dispatch<SetStateAction<Source>>;
}>({
  sort: "PriceAsc",
  setSort: (_) => {},
  source: "cigar-shop-world",
  setSource: (_) => {},
});

export const ResearchRoot: FC<{ children: ReactNode }> = ({ children }) => {
  const [sort, setSort] = useState<Sort>("PriceAsc");
  const [source, setSource] = useState<Source>("cigar-shop-world");

  return (
    <ResearchRootContext.Provider
      value={{
        sort,
        setSort,
        source,
        setSource,
      }}
    >
      {children}
    </ResearchRootContext.Provider>
  );
};

export function useResearchRootContext() {
  return useContext(ResearchRootContext);
}
